import Image from 'next/image'
import { isMobile } from 'react-device-detect'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { segment } from 'src/lib/Segments'

import { AiOutlineArrowLeft } from '@react-icons/all-files/ai/AiOutlineArrowLeft'
import { AiOutlineArrowRight } from '@react-icons/all-files/ai/AiOutlineArrowRight'

import styles from './carousel.module.css'

type MainSliderImageProps = {
  images: any
  showFullPage: boolean
  setShowFullPage: React.Dispatch<React.SetStateAction<boolean>>
  callbackImage: (slide: number) => void
  setNav1: React.Dispatch<React.SetStateAction<any>>
  nav2: any
  mainImageHeight: string
  mainImageObjectFit: any
  segmentBase: string
  loop?: boolean
}

function MainSliderImage({
  images,
  showFullPage,
  nav2,
  setShowFullPage,
  callbackImage,
  setNav1,
  mainImageHeight,
  mainImageObjectFit,
  segmentBase,
  loop = false,
}: MainSliderImageProps) {
  const CustomRightArrow = ({ onClick, currentSlide, slideCount }) => {
    if (slideCount === currentSlide + 1 && !loop) {
      return null
    }
    return (
      <button
        className="absolute inset-y-1/2 right-1 z-10 h-8 w-8 rounded-full bg-white 
          bg-opacity-80 p-2 shadow-md focus:outline-none"
        onClick={onClick}
      >
        <AiOutlineArrowRight />
      </button>
    )
  }

  const CustomLeftArrow = ({ onClick, currentSlide }) => {
    if (!currentSlide && !loop) {
      return null
    }
    return (
      <button
        onClick={onClick}
        className="absolute inset-y-1/2 left-1 z-10 h-8 w-8 rounded-full bg-white 
          bg-opacity-80 p-2 shadow-md focus:outline-none"
      >
        <AiOutlineArrowLeft />
      </button>
    )
  }

  const settings = {
    nextArrow: <CustomRightArrow onClick currentSlide slideCount />,
    prevArrow: <CustomLeftArrow onClick currentSlide />,
    infinite: loop,
  }

  return (
    <Slider
      asNavFor={nav2}
      ref={(slider1: any) => setNav1(slider1)}
      infinite={false}
      afterChange={callbackImage}
      {...settings}
    >
      {images.map((data, index) => {
        const imageSrc = showFullPage
          ? data?.imageL || data?.url || data
          : data?.imageM || data?.url || data

        return (
          <div
            className={`${
              showFullPage
                ? `${styles.fullScreenImageHeight} h-80 sm:h-auto`
                : `${mainImageHeight} ${isMobile ? 'h-80' : ''}`
            } 
            relative flex w-full cursor-pointer items-center justify-center focus:outline-none`}
            key={index}
            onClick={() => {
              segment?.[segmentBase + '_image_fullscreen_open']?.()
              setShowFullPage(true)
            }}
          >
            {isMobile}
            <Image
              src={imageSrc}
              alt={data?.imageDescription?.toString() ?? data?.description?.toString()}
              objectFit={mainImageObjectFit}
              layout="fill"
              className="h-full w-full sm:rounded-t-3xl"
              placeholder="blur"
              blurDataURL="/building-card-placeholder.png"
              unoptimized
            />
          </div>
        )
      })}
    </Slider>
  )
}

export default MainSliderImage
