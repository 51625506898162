import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
// import { Buildings } from 'src/data'
import { segment } from 'src/lib/Segments'

// import NewImageCarousel from '@components/generic/NewImageCarousel'
import { Review } from '@openigloo/common'
import { FaEdit } from '@react-icons/all-files/fa/FaEdit'
import { FaTrash } from '@react-icons/all-files/fa/FaTrash'
import { FiThumbsDown } from '@react-icons/all-files/fi/FiThumbsDown'
import { FiThumbsUp } from '@react-icons/all-files/fi/FiThumbsUp'
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown'
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp'
import { useAppSelector } from '@redux/hooks'
import { formatTimeAgo, getBuildingShareReviewURL, titleCase } from '@utility/Utilities'

import { StarRating } from '..'
import { Separator } from '..'
import Pill from '../Pill'

// import ReportModal from '../review/ReportModal'
// import ReviewImageDetails from './ReviewImageDetails'

const { publicRuntimeConfig } = getConfig()

const ShareItem = dynamic(() => import('@components/generic/ShareItem'))

const statusPillBgStyle = {
  rejected: 'bg-red-500',
  pending: 'bg-yellow-50',
  published: 'bg-green-500',
  draft: 'bg-purple-50',
}
const statusPillTextColorStyle = {
  rejected: '!text-white',
  pending: '!text-black',
  published: '!text-white',
  draft: '!text-black',
}

type ReviewCardProps = {
  review: Review
  onEditReview?: (review: Review) => void
  onDeleteReview?: (review: Review) => void
  showStatus?: boolean
  showPhotos?: boolean
  showFull?: boolean
  showReviewActions?: boolean
  segmentBase?: string
  isProfileView?: boolean
  showEditOptions?: boolean
  blurred?: boolean
  customStyle?: string
  showOnlyFewLines?: boolean
}

const ReviewCard = ({
  review,
  onEditReview,
  onDeleteReview,
  showFull = true,
  showReviewActions = true,
  showStatus,
  segmentBase = 'other',
  isProfileView = false,
  showEditOptions = true,
  blurred = false,
  customStyle = '',
  showPhotos = true,
}: ReviewCardProps): JSX.Element => {
  const { helpfulReviews } = useAppSelector((state) => state.currentUser)
  const [, setIsReporting] = useState(false)
  const [, setShowImages] = useState(false)
  const [, setIsReviewHelpful] = useState(false)
  const [, setHelpfulReviewNumber] = useState(null)
  const [, setIsHelpfulUpdating] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const router = useRouter()

  useEffect(() => {
    const { reviewId } = router.query
    if (reviewId === review.id) {
      const elem = document.getElementById(reviewId?.toString())
      if (elem) {
        elem.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  }, [])

  useEffect(() => {
    if (!isProfileView) {
      const elementId = document.getElementById('review' + review.id)
      if (
        elementId.offsetHeight < elementId.scrollHeight ||
        elementId.offsetWidth < elementId.scrollWidth
      ) {
        setShowMore(true)
      }
    }
    setHelpfulReviewNumber(review?.noHelpfulFor)
  }, [review])

  useEffect(() => {
    const helpfulReview = helpfulReviews.find((r) => r.id === review.id)
    if (helpfulReview) {
      setIsReviewHelpful(true)
      setHelpfulReviewNumber(helpfulReview.noHelpfulFor)
    }
    setIsHelpfulUpdating(false)
  }, [helpfulReviews])

  const getReviewShareUrl = () => {
    if (
      router &&
      (router.pathname.startsWith('/building') || router.pathname.startsWith('/contact'))
    ) {
      return `${publicRuntimeConfig.SITE_BASE_URL}${router.asPath}?reviewId=${review.id}`
    }
    if (review && review.building) {
      return `${getBuildingShareReviewURL(review.building)}?review=${review.id}`
    }
  }

  const shouldShowShare = () => {
    return (router && router.pathname.startsWith('/building/')) || review.building
  }

  function setReadMore() {
    if (!expanded) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  return (
    <div
      className={`mb-4 overflow-hidden rounded-lg border  bg-white py-6 ${
        router.query.reviewId && router?.query?.reviewId == review.id
          ? 'border-green-400'
          : 'border-light-30'
      } ${customStyle}`}
    >
      <div className="flex items-center">
        {showStatus && (
          <Pill
            label={review.status ? review.status : 'draft'}
            pillStyle={`${
              statusPillBgStyle[review.status ? review.status : 'draft']
            } text-xs mx-6 mb-4 px-4 py-1 ${statusPillTextColorStyle[review.status]}`}
          />
        )}
        {review.tenancyId && showEditOptions && (
          <div className="order-3 mb-4 ml-auto flex flex-wrap items-center justify-center pr-6">
            {onEditReview && (
              <span
                onClick={() => onEditReview(review)}
                className="flex cursor-pointer flex-row items-center justify-center text-center text-gray-500 hover:text-gray-900"
              >
                <span className="m-0.5 text-sm">
                  <FaEdit />
                </span>
                <span className="m-0.5 text-sm">Edit</span>
              </span>
            )}
            {onDeleteReview && (
              <span
                onClick={() => onDeleteReview(review)}
                className="ml-3 flex cursor-pointer flex-row items-center justify-center text-center text-gray-500 hover:text-red-600"
              >
                <span className="m-0.5 text-sm">
                  <FaTrash />
                </span>
                <span className="m-0.5 text-sm">Delete</span>
              </span>
            )}
          </div>
        )}
      </div>
      <div className="my-auto flex w-full justify-between px-6">
        <StarRating rating={review.rating} starSize={12} />
        <div className="hidden">{review.rating} stars</div>
        <div className="ml-2 text-sm text-dark-500">{formatTimeAgo(review.atApproved)}</div>
      </div>
      {/* {showImages && (
        <NewImageCarousel
          images={review.photos}
          showFullScreen={true}
          fullScreenTopDetails={<ReviewImageDetails review={review} />}
          onClose={() => {
            setShowImages(false)
            segment?.[segmentBase + '_review_image_fullscreen_close']?.()
          }}
          mainImageObjectFit="contain"
          segmentBase={segmentBase + '_review'}
        />
      )} */}
      {/* {isReporting && <ReportModal review={review} onClose={() => setIsReporting(false)} />} */}
      <div className="flex flex-wrap px-6 pb-1">
        <div className="review-title order-1 my-auto w-full text-xl font-semibold text-dark-900 sm:order-2 sm:w-max">
          {review.title}
        </div>
      </div>
      <div className="mt-1 mb-3 flex justify-between space-x-2 px-6 text-dark-700">
        {review.building && (
          <Link
            href={`/building/${review.building.cityId}/${review.building.id}/${review.building.slug}`}
            passHref
          >
            <p
              className="cursor-pointer text-xs font-normal underline hover:text-blue-300"
              onClick={() =>
                segment?.[segmentBase + '_review_address']?.(review.id, review.building.slug)
              }
            >
              {titleCase(review.building?.address)}
            </p>
          </Link>
        )}
        <div className="text-right text-xs">
          {review.isCurrentTenant ? 'Current Tenant' : 'Former Tenant'}
        </div>
      </div>

      <div className="flex w-full flex-col bg-white">
        <div className="inline-flex w-full flex-wrap justify-start px-6">
          {review.approveOwner && (
            <div className="flex items-center pr-4 text-green-500">
              <FiThumbsUp />
              <div className="pl-1 text-sm">Approves owner</div>
            </div>
          )}
          {review.approveOwner === false && (
            <div className="flex items-center pr-4 text-red-500">
              <FiThumbsDown />
              <div className="pl-1 text-sm">Does not approve owner</div>
            </div>
          )}
          {review.recommendBuilding && (
            <div className="flex items-center pr-4 text-green-500">
              <FiThumbsUp />
              <div className="pl-1 text-sm">Recommends building</div>
            </div>
          )}
          {review.recommendBuilding === false && (
            <div className="flex items-center pr-4 text-red-500">
              <FiThumbsDown />
              <div className="pl-1 text-sm">Does not recommend building</div>
            </div>
          )}
        </div>
        {!isProfileView && (
          <div className="relative">
            <Separator customStyle="mx-6" />
            {/* {blurred && (
              <div className="absolute top-1/2 left-1/2 w-4/5 -translate-y-1/2 -translate-x-1/2 text-center">
                <Image src={LockIcon} alt="lock" />
                <div className="pt-4 font-semibold text-dark-900">
                  Unlock all reviews on openigloo. Simply share an anonymous review for your current
                  or past rental experience. You can review any address in the United States.
                </div>
              </div>
            )} */}
            <div className={`relative`}>
              <div
                className={`h-full ${expanded ? '' : 'max-h-[185px] overflow-hidden'} px-6`}
                id={'review' + review.id}
              >
                <div className="text-base font-semibold text-bluegray-300">Pros:</div>
                <div
                  className={`review-data mt-1 mb-2 break-words text-sm text-dark-900 ${
                    blurred ? 'blur-sm filter line-clamp-2' : ''
                  }`}
                >
                  {!blurred ? `${review.pros}` : `${review.pros}`}
                </div>
                <div className="text-base font-semibold text-bluegray-300">Cons:</div>
                <div
                  className={`review-data mt-1 mb-2 break-words text-sm text-dark-900 ${
                    blurred ? 'blur-sm filter line-clamp-2' : ''
                  }`}
                >
                  {!blurred ? `${review.cons}` : `${review.cons}`}
                </div>
                {showFull && (
                  <>
                    {review.advice && (
                      <>
                        <div className="text-base font-semibold text-bluegray-300">
                          Advice to owner:
                        </div>
                        <div
                          className={`review-data mt-1 mb-2 break-words text-sm text-dark-900 ${
                            blurred ? 'blur-sm filter line-clamp-2' : ''
                          }`}
                        >
                          {review.advice}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              {showMore && (
                <div
                  onClick={setReadMore}
                  className="hover-underline-animation mx-6 mt-4 flex w-fit cursor-pointer items-center text-sm text-bluegray-300"
                >
                  <div>{expanded ? 'Read Less' : 'Read More'}</div>
                  <div className="pl-1">{!expanded ? <IoIosArrowDown /> : <IoIosArrowUp />}</div>
                </div>
              )}
              {showPhotos && review?.photos?.length > 0 && (
                <div>
                  <Separator customStyle="mx-6" />
                  <div
                    className={`mx-6 grid grid-cols-3 gap-x-2 sm:grid-cols-7 ${
                      blurred ? 'blur-sm filter' : ''
                    }`}
                  >
                    {review.photos.slice(0, isMobile ? 3 : 7).map((photo, index) => {
                      return (
                        <div className="relative h-24 w-full rounded-md" key={`photo-${index}`}>
                          {photo.includes('mp4') ? (
                            <video
                              width="320"
                              className="h-24 rounded-md"
                              playsInline
                              controls
                              preload="none"
                            >
                              <source src={photo} type="video/mp4" />
                            </video>
                          ) : (
                            <Image
                              src={photo}
                              alt={'review-card'}
                              layout="fill"
                              objectFit="cover"
                              // placeholder="blur"
                              className="cursor-pointer rounded-md"
                              onClick={() => {
                                setShowImages(true)
                                segment?.[segmentBase + '_review_image_fullscreen_open']?.()
                              }}
                              unoptimized
                            />
                          )}
                        </div>
                      )
                    })}
                  </div>
                  {(isMobile && review.photos.length > 3) ||
                  (!isMobile && review.photos.length > 7) ? (
                    <div
                      onClick={() => {
                        setShowImages(true)
                        segment?.[segmentBase + '_review_image_showmore']?.()
                      }}
                      className="mx-6 mt-4 flex w-fit cursor-pointer items-center text-xs text-bluegray-300 underline"
                    >
                      <div>Show all {review.photos.length} images</div>
                      <div className="pl-1">
                        <IoIosArrowDown />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {showFull && (
                <>
                  {showReviewActions && (
                    <>
                      <Separator customStyle="mx-6" />
                      <div className="flex w-full flex-row items-center justify-between space-x-2 px-6">
                        <button
                          className={`flex cursor-pointer items-center justify-center space-x-2 py-1 pr-2 text-sm font-thin text-bluegray-300 underline ${
                            review?.tenancyId && 'cursor-not-allowed text-gray-400'
                          }`}
                          onClick={() => {
                            setIsReporting(true)
                            segment?.[segmentBase + '_review_report']?.(
                              review.id,
                              review.building.slug
                            )
                          }}
                          disabled={review?.tenancyId ? true : false}
                        >
                          <span>Report</span>
                        </button>
                        <div className="flex items-center">
                          {shouldShowShare() && (
                            <div className="pl-3">
                              <ShareItem
                                label={''}
                                url={getReviewShareUrl()}
                                customIconName="sharePurple"
                                title={review.title}
                                shareNatively={false}
                                segmentBase={segmentBase + '_review'}
                                containerStyle="py-2 px-4 border rounded-2xl border-bluegray-300 bg-blue-25 cursor-pointer"
                                shareClicked={() => {
                                  segment?.[segmentBase + 'review_share_openigloo']?.()
                                }}
                                getShareOpt={(opt) => {
                                  switch (opt) {
                                    case '_share_copyurl':
                                      return segment?.[segmentBase + 'review_share_copyurl']?.()
                                    case '_share_email':
                                      return segment?.[segmentBase + 'review_share_email']?.()
                                    case '_share_twitter':
                                      return segment?.[segmentBase + 'review_share_twitter']?.()
                                    case '_share_facebook':
                                      return segment?.[segmentBase + 'review_share_facebook']?.()
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {review.ownerComment && (
                    <div className="mt-4 -mb-6 bg-light-10 px-6 pt-2 pb-4">
                      <div className="text-base font-semibold text-bluegray-500">
                        Response From Owner:
                      </div>
                      <div className={`pt-1 text-dark-900 ${blurred ? 'blur-sm filter' : ''}`}>
                        {review.ownerComment.comment}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ReviewCard
