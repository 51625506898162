import Image from 'next/image'
import React, { useContext, useState } from 'react'
import Dropzone from 'react-dropzone'
import { FiImage } from 'react-icons/fi'
import { BeatLoader } from 'react-spinners'
import { REVIEW_UPLOAD_EXAMPLES } from 'src/common/Constants'
import { reviewRemovePhotos, reviewUploadPhotos } from 'src/data/Review'
import { segment } from 'src/lib/Segments'
import { APContextReview } from 'src/pages/review/[buildingSlug]'

import { toast } from '@components/errors/ToastManager'
import { useAppSelector } from '@redux/hooks'

type Props = {
  setIsSavingPhotos: (params: boolean) => void
  segmentBase: string
  isOnline: boolean
  setShowOfflinePopup: (v: boolean) => void
}

const UploadPhotos = ({ setIsSavingPhotos, segmentBase, isOnline, setShowOfflinePopup }: Props) => {
  const { token, isLoggedIn } = useAppSelector((state) => state.currentUser)
  const { reviewData, displayPhotoList, setDisplayPhotoList, getReview } =
    useContext(APContextReview)

  const [loader, setLoader] = useState(false)

  async function uploadDocument(files: File[]) {
    setIsSavingPhotos(true)
    setLoader(true)
    try {
      if (files.length + (reviewData?.images?.length ?? 0) + displayPhotoList.length > 10) {
        toast.show({
          title: 'You can upload 10 photos or videos maximum.',
          message: '',
          duration: 3000,
        })
        return
      }
      const oversizedFile = files.find((file) => file.size / (1024 * 1024) > 20)
      if (oversizedFile) {
        toast.show({
          title: 'Each file must be 20MB or less.',
          message: '',
          duration: 3000,
        })
        return
      }
      if (isLoggedIn) {
        const uploadPromises = files.map((x, index) => {
          const imageObj = {
            file: x,
            order: index + 1,
          }
          return reviewUploadPhotos(token, imageObj, reviewData.id)
        })
        await Promise.all(uploadPromises)
        getReview(reviewData.id)
      } else {
        const newDisplayPhotoList = files.map((x) => {
          const imageObj = {
            imgUrl: URL.createObjectURL(x),
            file: x,
          }
          return imageObj
        })
        setDisplayPhotoList([...displayPhotoList, ...newDisplayPhotoList])
      }
    } catch {
      console.log('upload error')
    } finally {
      setIsSavingPhotos(false)
      setLoader(false)
    }
  }

  async function deleteImage(url: string, id?: string) {
    setLoader(true)
    try {
      if (isLoggedIn) {
        await reviewRemovePhotos(token, reviewData.id, { imageId: id })
        getReview(reviewData.id)
      } else {
        const imgArr = displayPhotoList.filter((x) => x.imgUrl !== url)
        setDisplayPhotoList(imgArr)
      }
    } catch {
      console.log('error')
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="rounded-xl bg-white p-3 sm:p-6 sm:shadow">
      <div className="text-center text-xl font-semibold text-lilac-500 sm:text-left">
        Photos (optional)
      </div>
      <p className="my-4 text-sm font-semibold text-dark-900">Examples can include:</p>
      <ul className="list-inside list-disc text-sm text-dark-700">
        {REVIEW_UPLOAD_EXAMPLES.map((example, ind) => (
          <li className="mb-1" key={`doc-${ind}`}>
            {example}
          </li>
        ))}
      </ul>
      <p className="py-6 text-sm text-dark-700">
        This section is optional but note that any photos you share will be published and attached
        to your review.{' '}
        <span className="font-semibold text-dark-900">
          Please remove or black out any identifiers that you do not want to be shared.
        </span>
      </p>
      <p className="mb-6 text-sm text-dark-700">You can upload 10 photos maximum.</p>
      <div className="relative">
        {loader && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <BeatLoader size={12} color="#061938" />
          </div>
        )}
        <div
          className={`rounded-lg border border-light-40 bg-light-10 py-7 px-4 ${
            loader && 'opacity-20'
          }`}
        >
          <Dropzone
            disabled={
              !isOnline ||
              loader ||
              (reviewData?.images?.length ?? 0) + displayPhotoList?.length == 10
            }
            onDrop={(e) => uploadDocument(e)}
            multiple={true}
            accept={['.png', '.jpeg', '.jpg', '.mp4', '.webm', '.mov', '.avi', '.mpeg', '.3gp']}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="cursor-pointer items-center justify-center text-center text-dark-900 sm:flex">
                    <div className="flex justify-center pb-4 sm:pb-0">
                      <FiImage className="mt-[3px] mr-4" />
                      <div className="hidden sm:block">Drag and drop files to upload</div>
                    </div>
                    <div className="hidden px-4 py-2 sm:block sm:py-0">or</div>
                    <div
                      className={`mx-auto w-fit rounded-full  px-6 py-2 text-sm sm:mx-0 ${
                        (reviewData?.images?.length ?? 0) + displayPhotoList?.length == 10 ||
                        !isOnline
                          ? 'cursor-not-allowed border-light-40 bg-light-30  text-mid-100'
                          : 'bg-bluegray-300 text-white'
                      }`}
                      onClick={() => {
                        if (!isOnline) {
                          setShowOfflinePopup(true)
                        }
                        segment?.review_upload_file()
                      }}
                    >
                      Upload photos
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          {isLoggedIn && (
            <>
              {reviewData.images?.length > 0 && (
                <div className="mt-8 px-4">
                  <div className="my-1 grid grid-cols-2 gap-8 px-4 sm:grid-cols-5">
                    {reviewData.images.map(
                      (url, index) =>
                        url.url && (
                          <div
                            key={`old-upload-${index}`}
                            className="relative col-span-1 mr-4 mb-4 w-full text-center"
                          >
                            <Image
                              src={url.url}
                              width={100}
                              height={100}
                              alt="review"
                              className="rounded-xl"
                              layout="responsive"
                              unoptimized
                            ></Image>
                            <div
                              className="cursor-pointer pt-2 text-sm text-bluegray-300 underline"
                              onClick={() => {
                                if (!loader) {
                                  segment?.[segmentBase + '_remove_uploadedfile']?.()
                                  deleteImage(url.url, url.id)
                                }
                              }}
                            >
                              Remove
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {displayPhotoList?.length > 0 && (
            <div className="mt-8 px-4">
              <div className="my-1 grid grid-cols-2 gap-8 px-4 sm:grid-cols-5">
                {displayPhotoList.map((url, index) => (
                  <div
                    key={`old-upload-${index}`}
                    className="relative col-span-1 mr-4 mb-4 w-full text-center"
                  >
                    <Image
                      src={url.imgUrl}
                      width={100}
                      height={100}
                      alt="review"
                      className="rounded-xl"
                      layout="responsive"
                      unoptimized
                    ></Image>
                    <div
                      className="cursor-pointer pt-2 text-sm text-bluegray-300 underline"
                      onClick={() => {
                        if (!loader) {
                          segment?.[segmentBase + '_remove_uploadedfile']?.()
                          deleteImage(url.imgUrl)
                        }
                      }}
                    >
                      Remove
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadPhotos
